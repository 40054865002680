import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography
} from "@mui/material";
import androidBadge from "../media/google-play-badge.png";
import iosBadge from "../media/ios.svg";
import CircularProgress from "@mui/material/CircularProgress";
import CustomButton from "../Components/CustomButton";
import logoImg from "../media/suspect.jpeg";
import Footer from "../Components/Footer";
import { UserAuth } from "../context/AuthContext";
import Iconify from "../Components/Iconify";
import { useNavigate, useParams } from "react-router-dom";
import Ticket from "../Components/profil/ticket";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db, functions } from "../firebase.config";
import { httpsCallable } from "firebase/functions";
import PdfTickets from "../Components/pdf/pdfTickets";
import { PDFDownloadLink } from "@react-pdf/renderer";
var QRCode = require("qrcode");

// ----------------------------------------------------------------------

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { xs: "100%", sm: 400 },
  bgcolor: "white",
  border: "1px solid white",
  borderRadius: "7px",
  boxShadow: 0,
  p: 3,
};

export default function Confirmation() {
  const { confirmID } = useParams();
  const [loading, setLoading] = React.useState();
  const [event, setEvent] = React.useState([]);
  const [eventID, setEventID] = React.useState([]);
  const [org, setOrg] = React.useState();
  const [bill, setBill] = React.useState([]);
  const [order, setOrder] = React.useState();
  const [qrcodes, setQrcodes] = React.useState([]);
  const [infos, setInfos] = React.useState([]);
  const [data, setData] = React.useState();
  const [refCommande, setRefCommande] = React.useState();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        //remove the paiement page

        // check
        const addMessage = httpsCallable(functions, "generateTicket");
        const result = await addMessage({ confirm: confirmID });
        console.log(result);
        // const q = query(collection(db, `evenement/${eventID}/tickets`));
        const docRef = doc(db, "confirmations", `${confirmID}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log(docSnap.data());
          const refBillSnap = await getDoc(
            doc(db, "bills", docSnap.data().refBill)
          );
          if (refBillSnap.exists()) {
            console.log(refBillSnap.data());
            const eventSnap = await getDoc(
              doc(db, "evenement", refBillSnap.data().event)
            );
            setEventID(eventSnap.id);
            setEvent(eventSnap.data());
            setBill(refBillSnap.data());
            const orderSnap = await getDoc(
              doc(db, `evenement/${refBillSnap.data().event}/commandes/`, refBillSnap.data().refCommande)
            );
            console.log(orderSnap.data())
            setOrder(orderSnap)
            const orgSnap = await getDoc(
              doc(db, `organisations/`, eventSnap.data().orgId)
            );
            console.log(orgSnap.data())
            setOrg(orgSnap)
          }
        }

        // console.log(docSnap.data());
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [confirmID]);

  // Modal download liste participants
  const [openDownloadModal, setOpenDownloadModal] = React.useState(false);
  const handleOpenDownloadModal = () => setOpenDownloadModal(true);
  const handleCloseDownloadModal = () => setOpenDownloadModal(false);

  const downloadTicket = async () => {
    // generation des qr code
    try {
      // commande 
      // billet
      const tmpbillet = order.data().billets
      const tmpTicketInfo = []
      console.log(order.data().billets)
      for (let index = 0; index < tmpbillet.length; index++) {
        const element = tmpbillet[index];
        const ticketSnap = await getDoc(
          doc(db, `evenement/${eventID}/billets/`, element)
        );
        console.log(ticketSnap.data())

        QRCode.toDataURL(
          element,
          {
            width: 130,
            margin: 1,
            errorCorrectionLevel: "H",
            color: {
              dark: "#000000",
              light: "#ffffff",
            },
          },
          (err, url) => {
            if (err) return console.log("erreur : ", err);
            setQrcodes((qrcodes) => [...qrcodes, url]);
            tmpTicketInfo.push({qr:url, price: ticketSnap.data().price, name: ticketSnap.data().nom, numero: element})
            // setQr(url);
          }
        );
      }
      console.log(tmpTicketInfo)
      setData({event, tickets: tmpTicketInfo, order: order.data(), bill, org: org.data() })
      
    } catch (error) {
      console.log(error);
    }
    handleOpenDownloadModal();
    // handleCloseDownloadModal();
  };
  return (
    <Container sx={{ paddingY: 15 }}>

      {loading ? (
        <Box
          sx={{ paddingY: 20 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress sx={{ color: "#5E17EB", width: 70, height: 70 }} />
          </Stack>
        </Box>
      ) : (
        <>
          <Stack justifyContent='center' alignItems={'center'}>
            <Stack spacing={4} sx={{
              width: { xs: "100%", sm: "70%" },
            }}>
              <Typography variant="body2">Retour a mes evenements</Typography>
              {
                order &&  <Typography variant="h5"
                >
                  <strong>Merci pour votre commande {order?.data()?.nom?.split(" ")?.length > 1 ? order?.data()?.nom?.split(" ")[1] : order?.data()?.nom} !</strong>
                </Typography>
              }
              <Stack spacing={2}>
              <Typography variant="body2">Ne manque plus jamais les evenements de <strong>{org?.data().nomOrg}</strong></Typography>
              <Box sx={{ padding: 2, border: '2px solid gainsboro', borderRadius: '10px' }}>
                
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack spacing={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Avatar src={org?.data().idVisuel} sx={{ width: 50, height: 50 }}/>
                    <Typography variant="h6"><strong>{org?.data().nomOrg}</strong></Typography>
                  </Stack>
                  <CustomButton buttonText={'S\'abonner'} type={'secondaire'} />
                </Stack>
              </Box>
              </Stack>
              
              <Box display="flex" justifyContent="center">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Card
                    elevation={5}
                    sx={{
                      width: { xs: "100%" },
                      borderRadius: { xs: "7px", sm: "7px" },
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: "100%", objectFit: "cover" }}
                      height={{ xs: "300", sm: "300" }}
                      image={event["idVisuel"]}
                    />
                  </Card>
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ width: { xs: "100%" } }}
                  justifyContent="center"
                >
                  <Stack
                    paddingY={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CustomButton buttonText={'Télécharger 1 billets'} onClick={() => downloadTicket()} type={'primaire'} />
                    <Modal
                      open={openDownloadModal}
                      onClose={handleCloseDownloadModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      sx={{ borderRadius: "7px" }}
                    >
                      <Box sx={styleModal}>
                        <Typography variant="body2">
                          Cliquez{" "}
                          <PDFDownloadLink
                            document={<PdfTickets data={data} info={data} qrs={qrcodes} />}
                            fileName={`${bill ? bill.refCommande : "undefined"
                              }.pdf`}
                          >
                            {({ blob, url, loading, error }) => {
                              console.log("erreur : ", error);
                              return loading ? "Genration du ticket......" : "ici";
                            }}
                          </PDFDownloadLink>{" "}
                          pour télécharger le ticket
                        </Typography>
                      </Box>
                    </Modal>
                  </Stack>
                  <Divider />
                  <Stack
                    paddingY={2}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" spacing={1}>
                      <Iconify
                        icon="bi:send"
                        sx={{
                          width: 24,
                          height: 24,
                          alignItems: "center",
                        }}
                      />
                      <Typography fontSize={17}>
                        Ton reçu ({bill?.totalPrix} CFA) et ton billet ont été
                        envoyés à <strong>{bill?.infoAcheteur?.email}</strong>
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  <Stack
                    paddingY={2}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" spacing={1}>
                      <Iconify
                        icon="solar:help-outline"
                        sx={{
                          width: 24,
                          height: 24,
                          alignItems: "center",
                        }}
                      />
                      <Typography fontSize={17}>
                        Besoin d’aide ? Contactez-nous ou visite notre FAQ
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  <Stack
                    paddingY={2}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" spacing={1}>
                      <Iconify
                        icon="ri:arrow-go-back-line"
                        sx={{
                          width: 24,
                          height: 24,
                          alignItems: "center",
                        }}
                      />
                      <Link
                        href={`https://choptonticket.com/fr/event/${eventID}`}
                        underline="none"
                      >
                        <Typography fontSize={17}>
                          Retour à la page de l'événement
                        </Typography>
                      </Link>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
              <Card elevation={0} sx={{ bgcolor: "#F3EDFD", marginY: 5 }}>
                <CardContent>
                  <Typography fontSize={16}>
                    La revente de billets est autorisée uniquement sur l'application
                    Choptonticket avec son systeme de revente intégré et sécurisé.
                  </Typography>
                </CardContent>
              </Card>
              <Stack direction="row" spacing={2} justifyContent="center">
                <img
                  src={androidBadge}
                  alt="illustration"
                  style={{ maxWidth: 130 }}
                />
                <img style={{ Width: 150 }} src={iosBadge} alt="Logo" />
              </Stack>
            </Stack>
          </Stack>

        </>
      )}
    </Container>
  );
}
