import {
  Card,
  Box,
  styled,
  Typography,
  CardMedia,
  Grid,
  Stack,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { fr } from "date-fns/locale";

const EventCard = ({
  img,
  price,
  name,
  eventID,
  debut,
  fin,
  city,
  lieu,
  type,
  ev,
  organisateur,
}) => {
  const EventBox = styled(Card)(({ theme }) => ({
    borderRadius: "7px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "2px 2px 18px #dcdcde",
    },
    backgroundColor: "#fff",
    margin: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 0, 0),
    },
  }));

  const ImgContainer = styled(Box)(() => ({
    //width: "100%",
    //height:"200"
  }));
  const navigate = useNavigate();
  const handleTimestamp = (e) => {
    if (e) {
      const date = e.toDate();
      const tmp = date.toJSON().split(".");
      return format((new Date(date.toJSON())), 'yyyy-MM-dd HH:mm', { locale: fr }).toString();
    }
    else {
      return '24/02/2013'
    }
  };

  return (
    <EventBox
      onClick={() => {
        navigate(`/fr/event/${eventID}`);
      }}
    >
      <ImgContainer>
        <CardMedia
          component="img"
          height="200"
          image={img}
          alt="green iguana"
        />
      </ImgContainer>

      <Box sx={{ paddingY: "1rem", paddingX: "1rem", height:'100%' }}>
        <Typography fontSize={17} variant="body2" sx={{ fontWeight: "700" }}>
          {name}
        </Typography>
        <Stack direction="column" spacing={1}>
          <Typography fontSize={14} fontWeight="550" color="#999999">
            {organisateur}
          </Typography>

          <Stack direction="row" spacing={1}>
            <Typography color='#5E17EB' fontSize={15}>
              {" "}

              {debut && format(new Date(handleTimestamp(debut)), 'dd MMM yyyy', { locale: fr })} {debut && format(new Date(handleTimestamp(debut)), 'HH', { locale: fr })}h{debut && format(new Date(handleTimestamp(debut)), 'mm', { locale: fr })} - {ev && ev.data().seanceActive === 1 && format(new Date(handleTimestamp(ev.data().dateFin)), 'dd MMM yyyy', { locale: fr })} {ev && format(new Date(handleTimestamp(ev.data().dateFin)), 'HH', { locale: fr })}h{ev && format(new Date(handleTimestamp(ev.data().dateFin)), 'mm', { locale: fr })}
            </Typography>
            
          </Stack>
          <Typography fontSize={15}>{lieu} | {ev && ev.data().minPrice !== 0 ? ` ${ev.data().minPrice} CFA` : 'Gratuit'}</Typography>
        </Stack>
      </Box>
    </EventBox>
  );
};

export default EventCard;
