import {
  Button,
  Card,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import homeIllustration from "../media/eventIllustration.png";
import CustomButton from "./CustomButton";
import androidBadge from "../media/google-play-badge.png";
import iosBadge from "../media/ios.svg";
import { Padding } from "@mui/icons-material";
import Iconify from "./Iconify";

const GetStarted = () => {
  const CustomContainer = styled(Container)(({ theme }) => ({
    backgroundColor: "#8C52FF",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 5,
      padding: theme.spacing(3, 3, 0, 3),
      width: "100%",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(10, 0, 10, 0),
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  }));

  return (
    <Box sx={{ mt: 5, backgroundColor: "#8C52FF" }}>
      <Container>
        <CustomBox>
          <CustomContainer>
          <Stack spacing={2}>
        <Typography
          sx={{ textAlign: 'center', fontSize: "45px", color: "white", fontWeight: "700", textTransform:'uppercase' }}
        >
          Plus prôche de vous avec plus de fonctionnalités
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
          <Link href="https://apps.apple.com" target="_blank" rel="noopener noreferrer">
            <img
              src={androidBadge}
              alt="Apple Store"
              style={{ maxWidth: 130 }}
            />
          </Link>
          <Link href="https://play.google.com" target="_blank" rel="noopener noreferrer">
            <img style={{ Width: 150 }} src={iosBadge} alt="Google Play" />
          </Link>
        </Box>
        <Stack spacing={4}>
          <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }}>
            <Card sx={{ paddingX: 4, paddingTop: 4, bgcolor:'#f2ecfd', borderRadius:'16px' }}>
              <Typography
                sx={{ textAlign: 'center', fontSize: "30px", fontWeight: "700", textTransform:'uppercase' }}
              >
                Découvre les meilleurs évènements
              </Typography>
              <Box
                sx={{
                  width: { xs: "100%" },
                  height: { xs: "100%" },
                }}
              >
                <img
                  src={homeIllustration}
                  alt="illustration"
                  style={{ width: "100%" }}
                />
              </Box>
            </Card>
            <Card sx={{ paddingX: 4, paddingTop: 4, bgcolor:'#f2ecfd', borderRadius:'16px' }}>
              <Typography
                sx={{ textAlign: 'center', fontSize: "30px", fontWeight: "700" }}
              >
                Changement de plan ? Revends ton billet !
              </Typography>
              <Box
                sx={{
                  width: { xs: "100%" },
                  height: { xs: "100%" },
                }}
              >
                <img
                  src={homeIllustration}
                  alt="illustration"
                  style={{ width: "100%" }}
                />
              </Box>
            </Card>
          </Stack>
        </Stack>
        </Stack>
          </CustomContainer>
        </CustomBox>
      </Container>
    </Box>
  );
};

export default GetStarted;
