import {
  Box,
  Container,
  Grid,
  Link,
  Skeleton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import React from "react";
import { db } from "../../firebase.config";
import CustomButton from "../CustomButton";
import EventCard from "../EventCard";
import Iconify from "../Iconify";

const types = [
  { nom: "Concert", icon: "material-symbols:music-note-rounded" },
  { nom: "Atelier", icon: "grommet-icons:workshop" },
  { nom: "Soirée clubbing", icon: "fluent-emoji-high-contrast:mirror-ball" },
  { nom: "Spéctacle", icon: "fluent-mdl2:balloons" },
  { nom: "Conférence", icon: "mdi:people-group" },
  { nom: "Exposition", icon: "streamline:travel-places-theater-mask-hobby-theater-masks-drama-event-show-entertainment" },
  
];

const All = () => {
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [orgs, setOrgs] = React.useState([])
  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  const handleTime = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };
  React.useEffect(() => {
    (async () => {
      try {
        const today = new Date();
        setLoading(true);
        setEvents([]);
        const q = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateFin",
            ">=",
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            )
          ),
          orderBy("dateFin", "desc"),
          orderBy("dateDebut", "asc"),
          limit(6)
        );
        const querySnapshot = await getDocs(q);
        // querySnapshot.empty()
        // console.log(querySnapshot);
        // setEvents(querySnapshot.docs);
        const tmp = await querySnapshot.docs.sort((a, b) => {
          const nameA = new Date(handleTime(a.data().dateDebut));
          const nameB = new Date(handleTime(b.data().dateDebut));
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        });
        tmp.forEach( async (d) => {
          // get event organisateur
          setLoading(true)
          const querySnapshot1 = await getDoc(doc(db,"organisations", d.data().orgId));
          
          setEvents((events) => [
            ...events,
            { infos: d, org: querySnapshot1.data().nomOrg },
          ]);
        }) 
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      
    })();
  }, []);

  return (
    <Box sx={{ mt: 5 }}>
      <Grid paddingBottom={5} container spacing={2}>
        {types.map((type) => (
          
          <Grid item xs={6} sm={3}>
            <Link href={`/fr/all--city/${type.nom}--events`} color="inherit" underline="none">
            <Box sx={{ border: " 1px solid #999999", borderRadius: "20px",
                    "&:hover": {
                      cursor:'pointer',
                      borderColor: "#5E17EB",
                    }, }}>
              <Stack direction="row">
                <Box sx={{ width: 40, color:'#5E17EB', bgcolor: "#F2ECFD", borderRadius: '20px 0 0 20px', display:'flex', alignItems:'center', justifyContent: 'center' }} padding={1}>
                  <Iconify icon={type.icon} />
                </Box>
                <Typography noWrap padding={1}sx={{textOverflow: "ellipsis",
                width: "100%"}}>{type.nom}</Typography>
              </Stack>
            </Box>
            </Link>  
          </Grid>
          
        ))}
      </Grid>
      {loading ? (
        <Grid container spacing={5} paddingY={2}>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={250} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" /> 
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={250} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={250} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container rowSpacing={5} paddingY={2}>
          {events.map((event, index) => (
            <Grid item xs={12} md={4} sx={{height: `100%`}}>
              <EventCard
                key={event.id}
                ev={event.infos}
                img={event.infos.data().idVisuel}
                name={event.infos.data().name ? event.infos.data().name: event.infos.data().nomEvent}
                lieu={event.infos.data().lieu_event}
                city={event.infos.data().city}
                type={event.infos.data().type}
                debut = {event.infos.data().dateDebut}
                organisateur={event.org}
                eventID={event.infos.id} 
                sx={{height: `100%`}}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Box
        paddingY={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {
          events.length !== 0 && <Link href={`/fr/all--city/all--events`} color="inherit" underline="none"><CustomButton
          backgroundColor="#1F1F1F"
          color="#fff"
          buttonText="Afficher plus"
          heroBtn={true}
        /></Link>
        }
      </Box>
    </Box>
  );
};

export default All;
