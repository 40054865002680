import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HomeIcon from "@mui/icons-material/Home";
import ContactsIcon from "@mui/icons-material/Contacts";
import logoImg from "../media/logo2.png";
import logoImg1 from "../media/logo1.png";
import { Container } from "@mui/system";
import CustomButton from "./CustomButton";
import fbIcon from "../media/fbicon.png";
import twitterIcon from "../media/twittericon.png";
import linkedinIcon from "../media/linkedinicon.png";
import {
  Drawer,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  TextField,
  Grid,
  Avatar,
  Link,
  InputAdornment,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { LinkOffTwoTone } from "@mui/icons-material";
import { UserAuth } from "../context/AuthContext";
import Iconify from "./Iconify";
import SwipeD from "./swipeable/Swipe";
import { useParams } from "react-router-dom";
import useResponsive from "./hooks/useResponsive";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { Variables } from "../context/VariableContext";
import AccountPopover from "./accountPopOver";

export const Navbar = () => {
  const { openLogin, setOpenLogin } = Variables();
  const { user, logIn } = UserAuth();
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const [mobileMenu, setMobileMenu] = useState({
    right: false,
  });
  const [urlPhoto, setUrl] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  React.useEffect(() => {
    (async () => {
      try {
        console.log("user profile : ");
        if (user) {
          const u1 = await getDoc(doc(db, "users", `${user.uid}`));
          if (u1.exists()) {
            console.log("user profile : ", u1.data().photoUrl);
            setNom(u1.data().nom);
            setPrenom(u1.data().prenom);
            setUrl(u1.data().photoUrl);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "Tab" || event.type === "Shift") {
      return;
    }

    setMobileMenu({ ...mobileMenu, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ padding: 2 }}>
        <IconButton
          aria-label="search"
          onClick={() => {
            console.log(mobileMenu);
          }}
        >
          <Iconify
            icon="material-symbols:close-rounded"
            sx={{ width: 24, height: 24 }}
          />
        </IconButton>
        <Grid container paddingTop={4} spacing={3}>
          <Grid item xs={12}>
            <Link href="/fr/" underline="hover">
              <NavLink variant="body2">Accueil</NavLink>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link
              href="http://api.whatsapp.com/send?phone=2250709219343"
              underline="hover"
              target="_blank" // pour ouvrir le lien dans un nouvel onglet
              rel="noopener noreferrer" // pour des raisons de sécurité
            >
              <NavLink variant="body2">Contactez-nous</NavLink>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Button
              endIcon={
                <Iconify icon="fluent-mdl2:go" sx={{ width: 10, height: 10 }} />
              }
              size="large"
              sx={{
                textTransform: "initial",
                border: "2px solid black",
                color: "black",
                fontWeight: 600,
                backgroundColor: "white",
                boxShadow: "0px 0px 0px 0px",
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                  boxShadow: "0px 0px 0px 0px",
                },
              }}
              onClick={() => {
                window.open("https://organisateurs.choptonticket.com");
              }}
              fullWidth
              variant="contained"
            >
              Organiser
            </Button>{" "}
          </Grid>
          <Grid item xs={12}>
            {user ? (
              <Link href="/fr/profile" underline="none">
                <Button
                  fullWidth
                  sx={{
                    textTransform: "initial",
                    border: "2px solid transparent",
                    color: "white",
                    fontWeight: 600,
                    backgroundColor: "#5E17EB",
                    borderRadius: "7px",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#5E17EB",
                      borderColor: "#5E17EB",
                    },
                  }}
                  variant="contained"
                >
                  Profil
                </Button>
              </Link>
            ) : (
              <Button
                fullWidth
                size="large"
                sx={{
                  textTransform: "initial",
                  border: "2px solid transparent",
                  color: "white",
                  fontWeight: 600,
                  backgroundColor: "#5E17EB",
                  borderRadius: "7px",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#5E17EB",
                    borderColor: "#5E17EB",
                  },
                }}
                variant="contained"
                onClick={toggleDrawerConnexion(!openLogin)}
              >
                Se connecter
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <IconBox>
              <img src={fbIcon} alt="fbIcon" style={{ cursor: "pointer" }} />
              <img
                src={twitterIcon}
                alt="twitterIcon"
                style={{ cursor: "pointer" }}
              />
              <img
                src={linkedinIcon}
                alt="linkedinIcon"
                style={{ cursor: "pointer" }}
              />
            </IconBox>
          </Grid>
        </Grid>
      </List>
    </Box>
  );

  const NavLink = styled(Typography)(({ theme }) => ({
    fontSize: "15",
    color: "black",
    fontWeight: "600",
    cursor: "pointer",
    "&:hover": {
      color: "#4F5361",
      fontWeight: "bold",
    },
  }));

  const NavbarLinksBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
    width: 300,
    [theme.breakpoints.down("md")]: {
      // display: "none",
      width: 150,
    },
  }));

  const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
    cursor: "pointer",
    display: "none",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  }));

  const NavbarContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    postion: "sticky",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    width: `100%`,
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  }));

  const NavbarBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),

    width: `100%`,

    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  }));

  const NavbarLogo = styled("img")(({ theme }) => ({
    cursor: "pointer",
    width: 50,
  }));

  //connexion section
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");

  const { city, filtres } = useParams();

  const seConnecter = async () => {
    try {
      console.log(email);
      console.log(password);
      await logIn(email, password);
      //setError(false)
      //navigate('/bo')
    } catch (error) {
      console.log(error);
      //setError(true)
    }
  };

  // drawer connection
  const [openConnexion, setOpenConnexion] = React.useState(false);
  const [connexion, setConnexion] = React.useState(true);
  const [count, setCount] = React.useState(0);

  const toggleDrawerConnexion = (open) => (event) => {
    setOpenLogin(open);
  };

  const IconBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  }));

  return (
    <Box sx={{ bgcolor: "white", position: "fixed", width: "100%", zIndex: 1 }}>
      <NavbarContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: {xs: "1rem", sm:"2.5rem"},
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link href="/fr/" underline="none">
              <NavbarLogo src={logoImg} alt="logo" />
            </Link>

            <Drawer
              anchor="right"
              open={mobileMenu["right"]}
              onClose={toggleDrawer("right", false)}
            >
              {list("right")}
            </Drawer>
          </Box>

          <NavbarLinksBox>
            {!city && !filtres && (
              <NavLink variant="body2">
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: {xs: 150, sm:300},
                    borderColor: "#f5f5f5",
                    bgcolor: " #f5f5f5",
                    cursor: "pointer",
                    borderRadius:'10px'
                  }}
                >
                  <Link href="/fr/all--city/all--event" underline="none">
                    <Stack direction="row">
                      <Box padding={1}>
                        <Iconify
                          icon="material-symbols:search-rounded"
                          sx={{ width: 24, height: 24 , color: "#7A7A7A"}}
                        />
                      </Box>
                      <Typography sx={{ color: "#7A7A7A" }} overflow='hidden' textOverflow= 'ellipsis' noWrap padding={1}>
                        Rechercher des événements
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
              </NavLink>
            )}
          </NavbarLinksBox>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {smUp && (
            <IconBox>
              <img src={fbIcon} alt="fbIcon" style={{ cursor: "pointer" }} />
              <img
                src={twitterIcon}
                alt="twitterIcon"
                style={{ cursor: "pointer" }}
              />
              <img
                src={linkedinIcon}
                alt="linkedinIcon"
                style={{ cursor: "pointer" }}
              />
            </IconBox>
          )}

          {smUp && (
            <>
              <Link
                href="http://api.whatsapp.com/send?phone=2250709219343"
                underline="hover"
                target="_blank" // pour ouvrir le lien dans un nouvel onglet
                rel="noopener noreferrer" // pour des raisons de sécurité
              >
                <NavLink variant="body2">Contactez-nous</NavLink>
              </Link>
              <Button
                endIcon={
                  <Iconify
                    icon="fluent-mdl2:go"
                    sx={{ width: 10, height: 10 }}
                  />
                }
                onClick={() => {
                  window.open("https://organisateurs.choptonticket.com");
                }}
                size="large"
                sx={{
                  textTransform: "initial",
                  border: "2px solid transparent",
                  color: "black",
                  fontWeight: 600,
                  backgroundColor: "white",
                  boxShadow: "0px 0px 0px 0px",
                  borderRadius: "7px",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    borderColor: "black",
                    boxShadow: "0px 0px 0px 0px",
                  },
                }}
                variant="contained"
              >
                Organiser
              </Button>{" "}
              
            </>
          )}

          {user ? (
            <AccountPopover />
          ) : (
            <Button
              sx={{
                textTransform: "initial",
                border: "2px solid transparent",
                color: "white",
                fontWeight: 600,
                backgroundColor: "#5E17EB",
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#5E17EB",
                  borderColor: "#5E17EB",
                },
              }}
              variant="contained"
              onClick={toggleDrawerConnexion(!openLogin)}
            >
              Se connecter
            </Button>
          )}
          <SwipeD open={openConnexion} count={count} />
          {/* <CustomMenuIcon onClick={toggleDrawer("right", true)} /> */}
        </Box>
      </NavbarContainer>
    </Box>
  );
};

export default Navbar;
