import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Container,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { db } from "../../firebase.config";
import CustomButton from "../CustomButton";
import EventCard from "../EventCard";
import Iconify from "../Iconify";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import ReserMobile2 from "./resMobile2";
import Timer from "./Timer";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const ReserMobile = ({ eventID }) => {
  //const navigate = useNavigate();
  const { user } = UserAuth();
  const { embedParams } = useParams();
  const [tickets, setTicket] = React.useState([]);
  const [ticketsList, setTicketList] = React.useState([]);
  const [seances, setSeance] = React.useState([]);
  const [seanceActive, setSeanceActive] = React.useState(0);
  const [event, setEvent] = React.useState();
  const [refreh, setRefresh] = React.useState(false);
  const [ticketTotals, setTicketTotal] = React.useState(0);
  const [quantite, setQuantite] = React.useState([]);
  const [montant, setMontant] = React.useState(0);
  const [reserve, setReserve] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [orgs, setOrgs] = React.useState([]);
  const [total, setTotal] = React.useState(montant);
  // Customization params
  const [textColor, setTextColor] = React.useState("0000");
  const [bgColor, setBgColor] = React.useState("ffff");
  const [primaryColor, setPrimaryColor] = React.useState("5E17EB");
  const [secondaryColor, setSecondaryColor] = React.useState("F3EDFD");
  const [tertiaireColor, setTertiaireColor] = React.useState("#1F1F1F");
  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const addTicket = (index) => {
    console.log("index : ", index);
    var tmp = montant;
    tmp = tmp + tickets[index].data().price;
    setMontant(tmp);
    setTicketTotal(parseInt(ticketTotals + 1));
    const tmp1 = { ...quantite };
    tmp1[index] += 1;
    setQuantite(tmp1);
    // new implementation
    if (ticketsList.length === 0) {
      setTicketList([
        {
          id: tickets[index].id,
          nom: tickets[index].data().ticket_name,
          prix: tickets[index].data().price,
          quantite: parseInt(1),
        },
      ]);
    } else {
      const exist = ticketsList.findIndex(({ id }) => id === tickets[index].id);
      if (exist === -1) {
        setTicketList((existItem) => {
          return [
            ...existItem.slice(0, ticketsList.length),
            {
              id: tickets[index].id,
              nom: tickets[index].data().ticket_name,
              prix: tickets[index].data().price,
              quantite: parseInt(1),
            },
            ...existItem.slice(ticketsList.length + 1),
          ];
        });
      } else {
        setTicketList((existItem) => {
          return [
            ...existItem.slice(0, exist),
            {
              id: tickets[index].id,
              nom: tickets[index].data().ticket_name,
              prix: tickets[index].data().price,
              quantite: parseInt(ticketsList[exist].quantite + 1),
            },
            ...existItem.slice(exist + 1),
          ];
        });
      }
    }

    console.log(ticketsList);
  };

  const removeTicket = (index) => {
    if (quantite[index] > 0) {
      var tmp = montant;
      tmp = tmp - tickets[index].data().price;
      setMontant(tmp);
      setTicketTotal(parseInt(ticketTotals - 1));
      const tmp1 = { ...quantite };
      tmp1[index] -= 1;
      setQuantite(tmp1);

      const exist = ticketsList.findIndex(({ id }) => id === tickets[index].id);
      console.log(exist);
      if (exist !== -1) {
        if (ticketsList[exist].quantite === 1) {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              ...existItem.slice(exist + 1),
            ];
          });
        } else {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              {
                id: tickets[index].id,
                nom: tickets[index].data().ticket_name,
                prix: tickets[index].data().price,
                quantite: parseInt(ticketsList[exist].quantite - 1),
              },
              ...existItem.slice(exist + 1),
            ];
          });
        }
      }
      console.log(ticketsList);
    }
  };
  function extractStringBetweenChars(str, startChar, endChar) {
    const startIndex = str.indexOf(startChar);
    let endIndex = 0 // Recherche à partir de l'indice suivant de startChar
    if (str.indexOf(endChar) < 0) {
      endIndex = str.length;
    }
    else {
      endIndex = str.indexOf(endChar, startIndex + 1);
    }
    if (startIndex !== -1 && endIndex !== -1) {
      return str.substring(startIndex+startChar.length, endIndex);
    } else {
      return ""; // Retourne une chaîne vide si les caractères spécifiés ne sont pas trouvés
    }
  }

  React.useEffect(() => {
    (async () => {
      setTicket([]);
      setSeance([]);
      setQuantite([]);
      try {
        setLoading(true);
        // const q = query(collection(db, `evenement/${eventID}/tickets`));
        //
        if (embedParams) {
          console.log("embeded  ...............");

          const tmpBg = extractStringBetweenChars(embedParams, 'bgColor=', '&');
          if (tmpBg !== "") {
            console.log(" bg color ...............");
            console.log(tmpBg);
            console.log(tmpBg);
            console.log(" bg color ...............");
            setBgColor(`${tmpBg}`)
          }
          const tmpPrimary = extractStringBetweenChars(embedParams, 'pColor=', '&');
          if (tmpPrimary !== "") {
            console.log(" primary color ...............");
            console.log(tmpPrimary);
            console.log(" primary color ...............");
            setPrimaryColor(`${tmpPrimary}`)
          }
          const tmpSecondary = extractStringBetweenChars(embedParams, 'sColor=', '&');
          if (tmpSecondary !== "") {
            setSecondaryColor(`${tmpSecondary}`)
          }
        }
        const docRef = doc(db, "evenement", `${eventID}`);
        const docSnap = await getDoc(docRef);
        setEvent(docSnap.data());
        // console.log(docSnap.data());
        const tmpdate = new Date(format(
          new Date(Date.now()),
          "yyyy-MM-dd",
        ))
        if (docSnap.data().seanceActive === 1) {
          const q1 = query(
            collection(db, `evenement/${eventID}/seances`),
            where('date', ">=", tmpdate),
            orderBy("date", "asc")
          );
          const snapshot1 = await getDocs(q1);
          snapshot1.forEach((d) => {
            setSeance((seances) => [...seances, d]);
          });
          snapshot1.docs[0].data().billets.forEach(async (billet) => {
            if (billet.status === 1) {
              const snapshot = await getDoc(
                doc(db, `evenement/${eventID}/tickets`, `${billet.id}`)
              );
              if (snapshot.exists()) {
                // console.log("Mes billet", snapshot.data().ticket_name);
                setTicket((tickets) => [...tickets, snapshot]);
                setQuantite((quantite) => [...quantite, 0]);
              }
            }
          });
        } else {
          const q = query(collection(db, `evenement/${eventID}/tickets`), where('status', '==', 1));
          const snapshot = await getDocs(q);
          snapshot.forEach((d) => {
            setTicket((tickets) => [...tickets, d]);
            setQuantite((quantite) => [...quantite, 0]);
          });
        }
        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [refreh, user]);

  const [moyenPaie, setMoyenpaie] = React.useState(0);
  const handleRadioChange = (event) => {
    var tmp = event.target.value;
    if (parseInt(tmp) === 0) {
      setTotal(montant);
      console.log(total);
    } else if (parseInt(tmp) === 1) {
      setTotal(montant + 200);
    } else {
      setTotal(montant + 500);
    }
    setMoyenpaie(event.target.value);
  };

  // if seance est active
  const changeSeance = (index) => {
    setTicket([]);
    setQuantite([]);
    setSeanceActive(index);
    seances[index].data().billets.forEach(async (billet) => {
      if (billet.status === 1) {
        const snapshot = await getDoc(
          doc(db, `evenement/${eventID}/tickets`, `${billet.id}`)
        );
        if (snapshot.exists()) {
          // console.log("Mes billet", snapshot.data());
          setTicket((tickets) => [...tickets, snapshot]);
          setQuantite((quantite) => [...quantite, 0]);
        }
      }
    });
  };
  const handleTimestamp = (e) => {
    if (e) {
      const date = e.toDate();
      const tmp = date.toJSON().split(".");
      return tmp[0];
    } else {
      return "24/02/2013";
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    (async () => {
      if (embedParams) {
        console.log("embeded  ...............");

        const tmpBg = extractStringBetweenChars(embedParams, 'bgColor=', '&');
        if (tmpBg !== "") {
          console.log(" bg color ...............");
          console.log(tmpBg);
          console.log(tmpBg);
          console.log(" bg color ...............");
          setBgColor(`${tmpBg}`)
        }
        const tmpPrimary = extractStringBetweenChars(embedParams, 'pColor=', '&');
        if (tmpPrimary !== "") {
          console.log(" primary color ...............");
          console.log(tmpPrimary.split("=")[1]);
          console.log(" primary color ...............");
          setPrimaryColor(`${tmpPrimary}`)
        }
        const tmpSecondary = extractStringBetweenChars(embedParams, 'sColor=', '&');
        if (tmpSecondary !== "") {

          setSecondaryColor(`${tmpSecondary}`)
        }
      }
    })();
  }, [embedParams]);

  return (
    <Box>
      <Box>
        <Grid container>
          {/** info de la commande */}
          <Grid item xs={12}>
            <Grid container>
              <Grid
                sx={{ overflowX: "auto", overflowY: "auto" }}
                item
                xs={12}
                paddingY={2}
                maxHeight={500}
              >
                <Stack
                  spacing={2}
                  paddingY={2}
                  direction="row"
                  sx={{ overflowX: "auto", overflowY: "auto", width: "100%" }}
                >
                  {seances.map((seance, index) =>
                    seanceActive === index ? (
                      <Box
                        padding={2}
                        sx={{
                          borderRadius: "7px",
                          border: "2px solid transparent",
                          cursor: "pointer",
                          bgcolor: "#F2ECFD",
                          borderColor: "#5E17EB",
                          maxWidth: 80,
                          "&:hover": {
                            borderColor: "#5E17EB",
                          },
                        }}
                        onClick={() => changeSeance(index)}
                      >
                        {console.log(seance.data())}
                        <Typography
                          textAlign="center"
                          sx={{ textTransform: "uppercase" }}
                        >
                          {format(
                            new Date(handleTimestamp(seance.data().date)),
                            "EEE dd MMM",
                            { locale: fr }
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        padding={2}
                        sx={{
                          borderRadius: "7px",
                          border: "2px solid transparent",
                          cursor: "pointer",
                          bgcolor: "#F2ECFD",
                          maxWidth: 80,
                          "&:hover": {
                            borderColor: "#5E17EB",
                          },
                        }}
                        onClick={() => changeSeance(index)}
                      >
                        <Typography
                          textAlign="center"
                          sx={{ textTransform: "uppercase" }}
                        >
                          {format(
                            new Date(handleTimestamp(seance.data().date)),
                            "EEE dd MMM",
                            { locale: fr }
                          )}
                        </Typography>
                      </Box>
                    )
                  )}
                </Stack>
                <Typography paddingBottom={2} variant="subtitle1">
                  Choisissez vos billets
                </Typography>

                <Grid container spacing={2} paddingBottom={10}>
                  {tickets.length === 0 && loading ? (
                    <>
                      <Grid item xs={12} md={12}>
                        <Skeleton variant="rectangular" height={150} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Skeleton variant="rectangular" height={150} />
                      </Grid>
                    </>
                  ) : (
                    tickets.map((ticket, index) => (
                      <Grid item xs={12} md={12}>
                        <Box
                          sx={{
                            padding: 2,
                            bgcolor: `#${secondaryColor}`,
                            color: `#${tertiaireColor}`,
                            borderRadius: "7px",
                          }}
                        >
                          <Grid container justifyContent="space-between">
                            <Grid>
                              <Typography fontSize={17} fontWeight={500}>
                                {ticket.data().ticket_name}
                              </Typography>
                              <Typography fontSize={16} >
                                {ticket.data().price} CFA
                              </Typography>
                            </Grid>
                            {seances.length !== 0 ? (
                              <Grid>
                                {seances[seanceActive].data().billets[
                                  seances[seanceActive]
                                    .data()
                                    .billets.findIndex(
                                      ({ id }) => id === ticket.id
                                    )
                                ].quota ===
                                  seances[seanceActive].data().billets[
                                    seances[seanceActive]
                                      .data()
                                      .billets.findIndex(
                                        ({ id }) => id === ticket.id
                                      )
                                  ].sell &&
                                  seances[seanceActive].data().billets[
                                    seances[seanceActive]
                                      .data()
                                      .billets.findIndex(
                                        ({ id }) => id === ticket.id
                                      )
                                  ].sell !== 0 ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: "5px",
                                      fontSize: 11,
                                      backgroundColor: "#FBE79D",
                                      border: "1px dashed #FBE79D",
                                    }}
                                  >
                                    épuisé
                                  </Box>
                                ) : (
                                  <Stack direction="row" spacing={1}>
                                    {quantite[index] === 0 ? (
                                      <IconButton
                                        size="small"
                                        disabled
                                        aria-label="fingerprint"
                                        sx={{
                                          bgcolor:  `#${primaryColor}`,
                                          color: "white",
                                        }}
                                      >
                                        <Iconify icon="ic:baseline-minus" />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() => removeTicket(index)}
                                        size="small"
                                        aria-label="fingerprint"
                                        sx={{
                                          bgcolor:  `#${primaryColor}`,
                                          color: "white",
                                        }}
                                      >
                                        <Iconify icon="ic:baseline-minus" />
                                      </IconButton>
                                    )}
                                    <Typography fontSize={18}>
                                      {quantite[index]}
                                    </Typography>
                                    <IconButton
                                      onClick={() => addTicket(index)}
                                      size="small"
                                      aria-label="fingerprint"
                                      sx={{
                                        bgcolor: `#${primaryColor}`,
                                        color: "white",
                                      }}
                                    >
                                      <Iconify icon="material-symbols:add" />
                                    </IconButton>
                                  </Stack>
                                )}
                              </Grid>
                            ) : (
                              <Grid>
                                {ticket.data().quota === ticket.data().sell &&
                                  ticket.data().sell !== 0 ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: "5px",
                                      fontSize: 11,
                                      backgroundColor: "#FBE79D",
                                      border: "1px dashed #FBE79D",
                                    }}
                                  >
                                    épuisé
                                  </Box>
                                ) : (
                                  <Stack direction="row" spacing={1}>
                                    {quantite[index] === 0 ? (
                                      <IconButton
                                        size="small"
                                        disabled
                                        aria-label="fingerprint"
                                        sx={{
                                          bgcolor:  `#${primaryColor}`,
                                          color: "white",
                                        }}
                                      >
                                        <Iconify icon="ic:baseline-minus" />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() => removeTicket(index)}
                                        size="small"
                                        aria-label="fingerprint"
                                        sx={{
                                          bgcolor:  `#${primaryColor}`,
                                          color: "white",
                                        }}
                                      >
                                        <Iconify icon="ic:baseline-minus" />
                                      </IconButton>
                                    )}
                                    <Typography fontSize={18}>
                                      {quantite[index]}
                                    </Typography>
                                    <IconButton
                                      onClick={() => addTicket(index)}
                                      size="small"
                                      aria-label="fingerprint"
                                      sx={{
                                        bgcolor:  `#${primaryColor}`,
                                        color: "white",
                                      }}
                                    >
                                      <Iconify icon="material-symbols:add" />
                                    </IconButton>
                                  </Stack>
                                )}
                              </Grid>
                            )}
                          </Grid>
                          {ticket.data().description &&
                            ticket.data().description !== "" && (
                              <Grid>
                                <Box>
                                  <Accordion
                                    elevation={0}
                                    sx={{ backgroundColor: "#999999" }}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        <Iconify icon="ep:arrow-down-bold" />
                                      }
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography
                                        fontSize={18}
                                        sx={{ color: "white" }}
                                      >
                                        Info sur le billet
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      sx={{
                                        backgroundColor: "white",
                                        borderRadius: "7px",
                                      }}
                                    >
                                      <Typography>
                                        {ticket.data().description}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                </Box>
                              </Grid>
                            )}
                        </Box>
                      </Grid>
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
            {ticketsList.length !== 0 && (
              <Card
                elevation={5}
                sx={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  paddingX: 3,
                  paddingY: 2,
                }}
              >

                <Stack direction="row" justifyContent="space-between">
                  <Typography fontSize={16} align="center" paddingBottom={1}>
                    Total
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={600}
                    align="center"
                    paddingBottom={1}
                  >
                    {montant} CFA
                  </Typography>
                </Stack>

                <Button
                  size="large"
                  fullWidth
                  sx={{
                    textTransform: "initial",
                    border: "2px solid transparent",
                    color: "white",
                    backgroundColor: "#5E17EB",
                    borderRadius: "7px",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#5E17EB",
                      borderColor: "#5E17EB",
                    },
                  }}
                  variant="contained"
                  onClick={handleOpen}
                >
                  Reservé maintenant
                </Button>
                {/** Verification de la commande et payement */}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Stack padding={1} direction="row">
                      <IconButton onClick={handleClose}>
                        <Iconify
                          icon="material-symbols:arrow-back-rounded"
                          sx={{ width: 24, height: 24 }}
                        />
                      </IconButton>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          textAlign="center"
                          color="#999999"
                        >
                          Commander
                        </Typography>
                      </Box>
                      <IconButton onClick={handleClose}>
                        <Iconify
                          icon="solar:close-circle-linear"
                          sx={{ width: 24, height: 24 }}
                        />
                      </IconButton>
                    </Stack>
                    <Timer />
                    <Divider />
                    <ReserMobile2
                      event={event}
                      ticketsList={ticketsList}
                      montant={montant}
                      seance={seances.length !== 0 ? seances[seanceActive].id : null}
                    />
                  </Box>
                </Modal>
              </Card>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReserMobile;
