import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
    Box,
    Divider,
    Typography,
    Stack,
    MenuItem,
    Avatar,
    ListItemIcon,
    ListItemText,
    Link,
    IconButton,
} from '@mui/material';
// components

// mocks_

import Iconify from './Iconify';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import { UserAuth } from '../context/AuthContext';
import useResponsive from './hooks/useResponsive';
import MenuPopover from './menuPopover';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Mon compte',
        icon: 'codicon:account',
        linkTo: 'fr/account',
    },
    {
        label: 'Mes billets',
        icon: 'ph:ticket-light',
        linkTo: '/fr/account/tickets',
    },
];

const MENU_OPTIONS_MOBILE = [
    {
        label: 'Mon compte',
        icon: 'codicon:account',
        linkTo: '/fr/account',
    },
    {
        label: 'Mes billets',
        icon: 'ph:ticket-light',
        linkTo: '/fr/account/tickets',
    },
    {
        label: "Centre d'aide",
        icon: 'oui:help',
        linkTo: 'https://support.choptonticket.com/fr',
    },
];

// ----------------------------------------------------------------------

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: '#5E17EB',
        },
        children: `${name.split(' ')[0][0]}`,
    };
}

const AccountPopover = () => {
    const { user, logOut } = UserAuth();
    const anchorRef = useRef(null);
    const [username, setUsername] = useState('');
    const [userInfo, setUser] = useState();
    const [org, setOrg] = useState();
    const [logo, setLogo] = useState();

    const navigate = useNavigate();

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef0 = doc(db, 'users', `${user.uid}`);
                // setLoading(true);
                const docSnap = await getDoc(docRef0);
                if (docSnap.exists()) {
                    setUsername(`${docSnap.data().nom}  ${docSnap.data().prenom}`.toUpperCase());
                    setUser(docSnap.data());
                    setLogo(docSnap.data().photoUrl);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData().catch(console.error);
    }, [user]);

    // log out function
    const handleLogout = async () => {
        try {
            await logOut();
        
        } catch (error) {
            console.log(error);
        }
        setOpen(null);
    };
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    p: 0,
                    borderRadius: '30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '30px',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[400], 0.8),
                        },
                    }),
                }}
            >
                {logo ? (
                    <Stack direction="row" spacing={1} padding={1} alignItems="center">
                        <Avatar src={logo} />
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={1} padding={1} alignItems="center">
                        {username && <Avatar {...stringAvatar(`${username}`)} />}
                    </Stack>
                )}
            </IconButton>
            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    width: 250,
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >

                <Stack spacing={1} sx={{ p: 1.5 }}>
                    <Box padding={1}>
                        <Typography noWrap fontSize={17} >
                            {userInfo && userInfo.email}
                        </Typography>
                    </Box>

                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {
                        mdUp ?
                            <>
                                {MENU_OPTIONS.map((option) => (
                                    <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                                        <ListItemIcon>
                                            <Iconify icon={option.icon} sx={{ width: 16, height: 16 }} />
                                        </ListItemIcon>
                                        <ListItemText ><Typography fontSize={16}>{option.label}</Typography></ListItemText>
                                    </MenuItem>
                                ))}</> :
                            <>
                                {MENU_OPTIONS_MOBILE.map((option) => (
                                    <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                                        <ListItemIcon>
                                            <Iconify icon={option.icon} sx={{ width: 16, height: 16 }} />
                                        </ListItemIcon>
                                        <ListItemText sx={{ fontSize: 16 }}><Typography variant='body2'>{option.label}</Typography></ListItemText>
                                    </MenuItem>
                                ))}</>

                    }

                    <Divider sx={{ borderStyle: 'dashed' }} />

                    <MenuItem onClick={handleLogout} sx={{ m: 1, color: 'red' }}>
                        <ListItemIcon>
                            <Iconify sx={{ color: 'red', height: 20, width: 20 }} icon="material-symbols-light:logout" />
                        </ListItemIcon>
                        <ListItemText><Typography variant='body2'>Deconnexion</Typography></ListItemText>
                    </MenuItem>

                </Stack>

            </MenuPopover>
        </>
    );
}

export default AccountPopover;