import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Modal,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useCallback } from "react";
import { useMemo } from "react";
import CustomButton from "../CustomButton";
import Iconify from "../Iconify";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PdfTicket from "../pdf/pdfTicket";
import { useEffect } from "react";
import { useState } from "react";
var QRCode = require("qrcode");

// ----------------------------------------------------------------------

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  bgcolor: "white",
  border: "1px solid white",
  borderRadius: "7px",
  boxShadow: 0,
  p: 3,
};

export default function Ticket({ img, info }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });
  const center = useMemo(() => ({ lat: 44, lng: -90 }), []);
  const [qrcode, setQr] = useState("");
  
  // Modal download liste participants
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const handleOpenDownloadModal = () => setOpenDownloadModal(true);
  const handleCloseDownloadModal = () => setOpenDownloadModal(false);

  const downloadTicket = async () => {
    
    handleOpenDownloadModal();
    // handleCloseDownloadModal();
  };

  if (!isLoaded)
    return <Skeleton variant="rounded" width={`100%`} height={250} />;
  return (
    <Card sx={{ marginY: 2 }}>
      <Grid padding={2} container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card sx={{ maxWidth: 500 }}>
            <CardMedia
              sx={{ height: 140 }}
              image={info.event.idVisuel}
              title="green iguana"
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} sx={{display: 'flex', flexDirection:'column', justifyContent:'center'}}>
          <Typography
            sx={{
              fontSize: "16px",
            }}
          >
            <strong>{info?.event?.name ? info?.event?.name : info?.event?.nomEvent}</strong>
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#5E17EB"
            }}
          >
            sam 7 janv. 21:00 - dim 8 janv. 02:00
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            {info.event.localisationDes ? info.event.localisationDes : `${info.event.lieu_event}, ${info.event.city}, ${info.event.country}`}
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              color: "#7A7A7A",
              fontWeight: "500",
              mb: 3,
            }}
          >
            Commande N° {info?.commande?.id}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            size="large"
            sx={{
              textTransform: "initial",
              border: "2px solid #5E17EB",
              backgroundColor: "#5E17EB",
              color: "white",
              borderRadius: "7px",
              boxShadow:'none',
              "&:hover": {
                backgroundColor: "#5E17EB",
                border: "2px solid #5E17EB",
                color: "white",
              },
            }}
            variant="contained"
            onClick={() => downloadTicket()}
          >
            <Stack
              spacing={1}
              direction="row"
              display="flex"
              alignItems="center"
            >
              <Iconify
                icon="material-symbols:download"
                sx={{ height: 24, width: 24 }}
              />
              <Typography fontSize={16}>Télécharger {info?.commande?.data()?.billets.length} billet(s)</Typography>{" "}
            </Stack>
          </Button>
          <Modal
            open={openDownloadModal}
            onClose={handleCloseDownloadModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ borderRadius: "7px" }}
          >
            <Box sx={styleModal}>
              <Typography variant="body2">
                Cliquez{" "}
                <PDFDownloadLink
                  document={<PdfTicket info={info} qr={qrcode} />}
                  fileName={`${
                    info ? info?.billet?.refCommande : "undefined"
                  }.pdf`}
                >
                  {({ blob, url, loading, error }) => {
                    console.log("erreur : ", error);
                    return loading ? "Genration du ticket......" : "ici";
                  }}
                </PDFDownloadLink>{" "}
                pour télécharger le ticket
              </Typography>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </Card>
  );
}
