import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
    Avatar,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Stack,
} from "@mui/material";
import logoImg from "../../media/suspect.jpeg";
import Footer from "../../Components/Footer";
import { UserAuth } from "../../context/AuthContext";
import Iconify from "../../Components/Iconify";
import { useNavigate } from "react-router-dom";
import Ticket from "../../Components/profil/ticket";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db, functions } from "../../firebase.config";
import { httpsCallable } from "firebase/functions";
import FuturTickets from "../../Components/billet/futurTicket";
import PastTickets from "../../Components/billet/pastTicket";

const options = ["Deconnexion"];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function Billets() {
    const { user, logOut } = UserAuth();
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [nom, setNom] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [finish, setFinish] = React.useState(false);
    const [infos, setInfo] = React.useState([]);
    const [prenom, setPrenom] = React.useState("");
    const [mail, setMail] = React.useState("");
    const [urlPhoto, setUrlPhoto] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // log out function
    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/", { replace: true });
        } catch (error) {
            console.log(error);
        }
        setAnchorEl(null);
        // setOpen(null);
    };

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: '#5E17EB',
            },
            children: `${name.split(' ')[0][0]}`,
        };
    }

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                if (user) {
                    console.log(user);
                    const u1 = await getDoc(doc(db, "users", `${user.uid}`));
                    if (u1.exists()) {
                        console.log(u1.data());
                        setNom(u1.data().nom);
                        setPrenom(u1.data().prenom);
                        setMail(u1.data().email);
                        setUrlPhoto(u1.data().photoUrl);
                        
                    }
                }
            } catch (error) {
                console.log(error);
            }
            setLoading(false)
        })();
    }, [user.uid]);

    return (
        <>
            <Container sx={{ paddingX: { xs: 2, sm: 5 }, paddingY: 10 }}>
                <Stack justifyContent='center' alignItems={'center'}>
                    <Stack paddingTop={5} spacing={4} sx={{
                        width: { xs: "100%", sm: "70%" },
                    }}>

                        <Typography
                            sx={{
                                fontSize: "30px",
                                fontWeight: "600",
                            }}

                        >
                            <strong>Mes billets</strong>
                        </Typography>

                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <Box sx={{ width: { xs: "100%" } }}>
                                <Box>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                    >
                                        <Tab label="A venir" {...a11yProps(0)} />
                                        <Tab label="Passés" {...a11yProps(1)} />
                                        
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <FuturTickets />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <PastTickets />
                                </TabPanel>
                            </Box>
                        </Box>

                    </Stack>
                </Stack>



            </Container>
            <Footer />
        </>
    );
}
