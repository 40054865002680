import { Navigate, useParams, useRoutes } from "react-router-dom";
import ProtectedRoute from "./Components/protectedRoute";
// layouts

//

import Layout from "./layout/layout";
import EventPage from "./pages/eventPage";
import Home from "./pages/home";
import Profile from "./pages/profile";
import SearchPage from "./pages/searchPage";
import Confirmation from "./pages/confirmation";
import Organisateur from "./pages/organisateur";
import SiteGenerate from "./generate_site/site";
import EventPageEmbeded from "./pages/embeded/eventPageEmbeded";
import ResetPass from "./pages/resetPass";
import Billets from "./pages/profile/billets";

// ----------------------------------------------------------------------

export default function Router() {
  const params = useParams();
  return useRoutes([
    {
      path: "/",
      element: <Layout />,

      children: [
        { path: "/", element: <Navigate to="/fr" /> },
        { path: "fr", element: <Home /> },
        {
          path: "fr/account",
          element: (
            <ProtectedRoute>
              <Profile />,
            </ProtectedRoute>
          ),
        },
        {
          path: "fr/account/tickets",
          element: (
            <ProtectedRoute>
              <Billets />,
            </ProtectedRoute>
          ),
        },
        { path: "fr/event/:eventID", element: <EventPage /> },
        { path: "event/:eventID", element: <EventPage /> },
        { path: "fr/event/:eventID/tracking/:plateforme/:trackingID", element: <EventPage /> },
        { path: "fr/event/:eventID/:trackingSelling", element: <EventPage /> },
        { path: "fr/organisateur/:orgID", element: <Organisateur /> },
        { path: "fr/checkout/:confirmID/confirmation", element: <Confirmation /> },
        { path: "fr/:city/:filtres", element: <SearchPage /> },
        { path: "fr/:city/:filtres/:name", element: <SearchPage /> },
      ],
    },
    { path: "fr/event_embed/:eventID", element: <EventPageEmbeded /> },
    {
      path: "/mini-site/:eventID",
      element: <SiteGenerate/>,
    },
    {
      path: "/reset-password/:upn",
      element: <ResetPass/>,
    },
    {
      path: "/embeded/:eventID",
      element: <SiteGenerate/>,
    },
    {
      path: "fr/event_embed/:eventID/:embedParams",
      element: <EventPageEmbeded/>,
    },
    {
      path: "/",
      element: <Navigate to="/fr" replace />,
    },
  ]);
}
