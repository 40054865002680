import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import CustomButton from "../Components/CustomButton";
import logoImg from "../media/suspect.jpeg";
import Iconify from "../Components/Iconify";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { useNavigate, useParams } from "react-router-dom";
import { borderRadius } from "@mui/system";
import { format } from "date-fns";
import { fr, id } from "date-fns/locale";
import Maps from "../Components/map/Maps";
import useResponsive from "../Components/hooks/useResponsive";
import ReserDesktop from "../Components/reservation/reserDesktop";
import ReserMobile from "../Components/reservation/resMobile";
import Footer from "../Components/Footer";
import Contact from "../Components/Contact";
import { Variables } from "../context/VariableContext";
import { UserAuth } from "../context/AuthContext";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Cookies from "js-cookie";
import AffTracker from "../Components/tracker/affTracker";
// import * as pay from "https://paydunya.com/assets/psr/js/psr.paydunya.min.js"

var paydunya = require("paydunya");

var setup = new paydunya.Setup({
  masterKey: "DNrUjYFV-6HiQ-NNu5-DAgU-WizSswjzZW6I",
  privateKey: "test_private_Qyqe0MaokWBMmU1m5d1kNHcj7fs",
  publicKey: "test_public_8FAyuNRDgwV2v8Yujc8QHvlYtuS",
  token: "vT8PF5dQR3zx7r9OcplJ",
  mode: "test",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 2,
};

export default function EventPage() {
  // quill rich text
  const modules = {
    toolbar: [
      // [{ 'font': [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean"],
    ],
  };

  const formats = [
    // 'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];
  const navigate = useNavigate();
  const { eventID } = useParams();
  const { trackingID } = useParams();
  const { plateforme } = useParams();
  const { trackingSelling } = useParams();
  const [sellingID, setSellingID] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [tickets, setTicket] = React.useState([]);
  const [ticketsList, setTicketList] = React.useState([]);
  const [seances, setSeance] = React.useState([]);
  const [seanceActive, setSeanceActive] = React.useState(0);
  const [event, setEvent] = React.useState();
  const [refreh, setRefresh] = React.useState(false);
  const [ticketTotals, setTicketTotal] = React.useState(0);
  const [quantite, setQuantite] = React.useState([]);
  const [montant, setMontant] = React.useState(0);
  const [reserve, setReserve] = React.useState(false);
  const [org, setOrg] = React.useState([]);
  const [infoUser, setInfoUser] = React.useState([]);
  const [isFollow, setIsFollow] = React.useState(false);
  const [addDiscount, setAddDiscount] = React.useState(false);
  const [discountField, setDiscountField] = React.useState("");
  const [discount, setDiscount] = React.useState();
  const [discountMsg, setDiscountMsg] = React.useState("");
  const [startVerifyDiscount, setStartVerifyDiscount] = React.useState(false);
  const [discountLoad, setDiscountLoad] = React.useState(false);
  const [desc, setDesc] = useState("");
  const { user } = UserAuth();
  const [isSticky, setSticky] = useState(0);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  const today = new Date();
  const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes());

  useEffect(() => {
    console.log("ok");
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  // reserve field

  const [moyenPaie, setMoyenpaie] = React.useState(0);
  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setMoyenpaie(event.target.value);
  };

  React.useEffect(() => {
    (async () => {
      console.log(
        "start____________________________________________________________"
      );
      try {
        setQuantite([]);
        setLoading(true);
        // const q = query(collection(db, `evenement/${eventID}/tickets`));
        const docRef = doc(db, "evenement", `${eventID}`);
        const docSnap = await getDoc(docRef);

        // console.log(docSnap.data());
        if (docSnap.exists()) {
          setEvent(docSnap.data());
          const querySnapshot1 = await getDoc(
            doc(db, "organisations", docSnap.data().orgId)
          );
          setOrg(querySnapshot1.data());

          //trackinnng
          if (trackingID && plateforme) {
            console.log('tracking...............')
            console.log(docSnap.data().orgId)
            console.log(eventID)
            const snapshotfb = await getDoc(doc(db, `organisations/${docSnap.data().orgId}/sosm/${docSnap.data().orgId}/${eventID}`, `facebook`));
            if (snapshotfb.exists()) {
              console.log(snapshotfb)
              if (snapshotfb.data().code === trackingID) {
                await updateDoc(doc(db, `organisations/${docSnap.data().orgId}/sosm/${docSnap.data().orgId}/${eventID}`, `${plateforme === 'fb' ? 'facebook' : ''}`), {
                  click: increment(1),
                });
                navigate(`/fr/event/${docSnap.id}/fb-${trackingID}`)
              } else {
                console.log('tracking id not corresponding')
              }
            }

          }

          //tracking selling
          if (trackingSelling) {
            console.log('tracking selling ...............')
            const tmp = trackingSelling.split('-')
            const snapshotfb = await getDoc(doc(db, `organisations/${docSnap.data().orgId}/sosm/${docSnap.data().orgId}/${eventID}`, `facebook`));
            if (snapshotfb.exists()) {
              console.log(snapshotfb.data().code)
              if (snapshotfb.data().code === tmp[1]) {
                setSellingID(trackingSelling)
              } else {
                console.log('tracking id not corresponding')
              }
            }

          }

          if (docSnap.data().seanceActive === 1) {
            const tmpdate = new Date(
              format(new Date(Date.now()), "yyyy-MM-dd")
            );
            //const tmpdate = new Date(Date.now())
            console.log(tmpdate);
            const q1 = query(
              collection(db, `evenement/${eventID}/seances`),
              where("date", ">=", tmpdate),
              orderBy("date", "asc")
            );
            const snapshot1 = await getDocs(q1);
            setSeance([]);
            snapshot1.forEach((d) => {
              setSeance((seances) => [...seances, d]);
            });
            snapshot1.docs[0].data().billets.forEach(async (billet) => {
              if (billet.status === 1) {
                const snapshot = await getDoc(
                  doc(db, `evenement/${eventID}/tickets`, `${billet.id}`)
                );
                if (snapshot.exists()) {
                  setTicket([]);
                  // console.log("Mes billet", snapshot.data().ticket_name);
                  setTicket((tickets) => [...tickets, snapshot]);
                  setQuantite((quantite) => [...quantite, 0]);
                }
              }
            });
          } else {
            console.log(
              "start1____________________________________________________________",
              tickets.length
            );
            const q = query(
              collection(db, `evenement/${eventID}/tickets`),
              where("status", "==", 1)
            );
            const snapshot = await getDocs(q);
            console.log(
              "snap__________________________________________________________",
              snapshot.size
            );
            setTicket([]);
            snapshot.forEach((d) => {
              console.log(
                "start2____________________________________________________________",
                tickets.length
              );

              setTicket((tickets) => [...tickets, d]);
              setQuantite((quantite) => [...quantite, 0]);
            });
          }
        }
        if (user) {
          console.log(user.uid);
          const userRef = await getDoc(doc(db, "users", `${user.uid}`));
          setInfoUser(userRef);
        }
        const q = query(collection(db, `evenement/${eventID}/description`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          // setDescription((description) => [d]);
          // setDesc(d.data().valeur);
          console.log("desc : ", d.data());
          if (d.data().valeur) {
            // setEditorState(() => EditorState.createWithContent(convertFromHTML(d.data().valeur)));
            setDesc(d.data().valeur);
          } else {
            // setEditorState(() => EditorState.createWithContent(convertFromHTML(d.data().value)));
            setDesc(d.data().value);
          }
        });

        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [refreh, user]);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const u1 = await getDoc(
          doc(db, `users/${user.uid}/followings`, event.orgId)
        );
        if (u1.exists()) {
          setIsFollow(true);
        } else {
          setIsFollow(false);
        }
      } catch (error) {
        console.log(error.msg);
      }
      setLoading(false);
    })();
  }, [refreh, event]);

  React.useEffect(() => {
    (async () => {
      VerifyDiscount();
    })();
  }, [startVerifyDiscount, ticketTotals]);

  React.useEffect(() => {
    (async () => {
      setOpenContact(false);
      setOpenReservation(false);
    })();
  }, []);

  const addTicket = (index) => {
    console.log("index : ", index);
    var tmp = montant;
    tmp = tmp + tickets[index].data().price;
    setMontant(tmp);
    setTicketTotal(parseInt(ticketTotals + 1));
    const tmp1 = { ...quantite };
    tmp1[index] += 1;
    setQuantite(tmp1);
    // new implementation
    if (ticketsList.length === 0) {
      setTicketList([
        {
          id: tickets[index].id,
          nom: tickets[index].data().ticket_name,
          prix: tickets[index].data().price,
          quantite: parseInt(1),
        },
      ]);
    } else {
      const exist = ticketsList.findIndex(({ id }) => id === tickets[index].id);
      if (exist === -1) {
        setTicketList((existItem) => {
          return [
            ...existItem.slice(0, ticketsList.length),
            {
              id: tickets[index].id,
              nom: tickets[index].data().ticket_name,
              prix: tickets[index].data().price,
              quantite: parseInt(1),
            },
            ...existItem.slice(ticketsList.length + 1),
          ];
        });
      } else {
        setTicketList((existItem) => {
          return [
            ...existItem.slice(0, exist),
            {
              id: tickets[index].id,
              nom: tickets[index].data().ticket_name,
              prix: tickets[index].data().price,
              quantite: parseInt(ticketsList[exist].quantite + 1),
            },
            ...existItem.slice(exist + 1),
          ];
        });
      }
    }

    console.log(ticketsList);
  };

  const removeTicket = (index) => {
    if (quantite[index] > 0) {
      var tmp = montant;
      tmp = tmp - tickets[index].data().price;
      setMontant(tmp);
      setTicketTotal(parseInt(ticketTotals - 1));
      const tmp1 = { ...quantite };
      tmp1[index] -= 1;
      setQuantite(tmp1);

      const exist = ticketsList.findIndex(({ id }) => id === tickets[index].id);
      console.log(exist);
      if (exist !== -1) {
        if (ticketsList[exist].quantite === 1) {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              ...existItem.slice(exist + 1),
            ];
          });
        } else {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              {
                id: tickets[index].id,
                nom: tickets[index].data().ticket_name,
                prix: tickets[index].data().price,
                quantite: parseInt(ticketsList[exist].quantite - 1),
              },
              ...existItem.slice(exist + 1),
            ];
          });
        }
      }
      console.log(ticketsList);
    }
  };

  // application de code promo
  const VerifyDiscount = async () => {
    setDiscountLoad(true);
    if (!addDiscount || discountField === "") {
      return;
    }
    const q = query(
      collection(db, `evenement/${eventID}/codePromo`),
      where("code", "==", discountField)
    );
    const snapshot = await getDocs(q);
    if (snapshot.docs.length !== 0) {
      // console.log("Mes billet", snapshot.data());
      if (
        snapshot.docs[0].data().usage.max > snapshot.docs[0].data().usage.now
      ) {
        if (snapshot.docs[0].data().billet.length === 0) {
          // la reduction n'est pas limité à un billet
          if (snapshot.docs[0].data().reduc.type === "percent") {
            if (ticketsList.length === 1) {
              Cookies.set("idDiscount", snapshot.docs[0].id, { expires: 1 / 72 });
              setDiscountMsg("Code validé");
              setDiscount({
                id: snapshot.docs[0].id,
                value:
                  (ticketsList[0].prix * snapshot.docs[0].data().reduc.value) /
                  100,
              });
            } else if (ticketsList.length > 1) {
              const ticketListTmp = ticketsList;
              ticketListTmp.sort((a, b) => a.prix - b.prix);
              var index = 0;
              for (let i = 0; i < ticketListTmp.length; i++) {
                const element = ticketListTmp[i];
                if (ticketListTmp[i].prix === 0 && ticketListTmp.length > 1) {
                  index += 1;
                } else {
                  break;
                }
              }
              Cookies.set("idDiscount", snapshot.docs[0].id, { expires: 1 / 72 });
              setDiscount({
                id: snapshot.docs[0].id,
                value:
                  (ticketListTmp[index].prix *
                    snapshot.docs[0].data().reduc.value) /
                  100,
              });
              setDiscountMsg(
                "Code validé ! Cependant il ne s'applique qu'a un seul billet"
              );
            }
          } else {
            Cookies.set("idDiscount", snapshot.docs[0].id, { expires: 1 / 72 });
            if (ticketTotals > 1) {
              setDiscountMsg(
                "Code validé ! Cependant il ne s'applique qu'a un seul billet"
              );
            } else {
              setDiscountMsg("Code validé");
            }
            setDiscount({
              id: snapshot.docs[0].id,
              value: snapshot.docs[0].data().reduc.value,
            });
          }
        } else {
          // la reduction peut etre limité à certains billet
          let find = 0;
          let findTab = [];
          console.log(ticketsList);
          console.log(snapshot.docs[0].data().billet);
          for (
            let index = 0;
            index < snapshot.docs[0].data().billet.length;
            index++
          ) {
            const id = snapshot.docs[0].data().billet[index];
            const exist = ticketsList.findIndex(
              ({ id }) => id === snapshot.docs[0].data().billet[index]
            );
            console.log(exist);
            if (exist !== -1) {
              findTab.push(ticketsList[exist]);
              find = 1;
            }
          }

          if (find === 1) {
            console.log(findTab);
            if (snapshot.docs[0].data().reduc.type === "percent") {
              if (findTab.length === 1) {
                setDiscountMsg("Code validé");
                setDiscount({
                  id: snapshot.docs[0].id,
                  value:
                    (findTab[0].prix * snapshot.docs[0].data().reduc.value) /
                    100,
                });
              } else if (findTab.length > 1) {
                const ticketListTmp = findTab;
                ticketListTmp.sort((a, b) => a.prix - b.prix);
                setDiscount({
                  id: snapshot.docs[0].id,
                  value:
                    (ticketListTmp[0].prix *
                      snapshot.docs[0].data().reduc.value) /
                    100,
                });
                setDiscountMsg(
                  "Code validé ! Cependant il ne s'applique qu'a un seul billet"
                );
              }
            } else {
              if (ticketTotals > 1) {
                setDiscountMsg(
                  "Code validé ! Cependant il ne s'applique qu'a un seul billet"
                );
              } else {
                setDiscountMsg("Code validé");
              }
              setDiscount({
                id: snapshot.docs[0].id,
                value: snapshot.docs[0].data().reduc.value,
              });
            }
          } else {
            setDiscountMsg("Code non valable");
          }
        }
      } else {
        console.log("code non valable");
        setDiscountMsg("code non valable");
      }
    } else {
      console.log("code non valable");
      setDiscountMsg("Code non valable");
    }
    setDiscountLoad(false);
  };

  // if seance est active
  const changeSeance = (index) => {
    setTicketTotal(0);
    setTicketList([]);
    setOpenContact(false);
    setTicket([]);
    setQuantite([]);
    setSeanceActive(index);
    seances[index].data().billets.forEach(async (billet) => {
      if (billet.status === 1) {
        const snapshot = await getDoc(
          doc(db, `evenement/${eventID}/tickets`, `${billet.id}`)
        );
        if (snapshot.exists()) {
          // console.log("Mes billet", snapshot.data());
          setTicket((tickets) => [...tickets, snapshot]);
          setQuantite((quantite) => [...quantite, 0]);
        }
      }
    });
  };
  const handleTimestamp = (e) => {
    if (e) {
      const date = e.toDate();
      const tmp = date.toJSON().split(".");
      return tmp[0];
    } else {
      return "24/02/2013";
    }
  };

  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal contact

  const handleOpenContact = () => {
    setOpenContact(true);
  };
  const handleCloseContact = () => {
    setOpenContact(false);
  };

  const {
    openContact,
    setOpenContact,
    openReservation,
    setOpenReservation,
    openLogin,
    setOpenLogin,
  } = Variables();

  // follow

  const follow = async () => {
    if (user) {
      var exist;
      console.log(user.email);
      console.log(infoUser);
      setLoading(true);
      try {
        const u1 = await getDoc(
          doc(db, `users/${user.uid}/followings`, event.orgId)
        );
        if (u1.exists()) {
          exist = 1;
        } else {
          exist = -1;
        }

        if (exist === -1) {
          // abonnement
          const u2 = await getDoc(
            doc(
              db,
              `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
              user.email
            )
          );
          if (u2.exists()) {
            await updateDoc(
              doc(
                db,
                `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
                user.email
              ),
              {
                nom: infoUser.data().nom,
                prenom: infoUser.data().prenom,
                push: true,
                newslettres: true,
              }
            );
          } else {
            await setDoc(
              doc(
                db,
                `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
                user.email
              ),
              {
                uid: user.uid,
                nom: infoUser.data().nom,
                prenom: infoUser.data().prenom,
                push: true,
                newslettres: true,
              }
            );
          }

          await setDoc(doc(db, `users/${user.uid}/followings/${event.orgId}`), {
            push: true,
            newslettres: true,
          });
        } else {
          // desabonnement
          await updateDoc(
            doc(
              db,
              `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
              user.email
            ),
            {
              uid: user.uid,
              nom: infoUser.data().nom,
              prenom: infoUser.data().prenom,
              push: false,
              newslettres: false,
            }
          );

          await deleteDoc(doc(db, `users/${user.uid}/followings`, event.orgId));
        }

        // setConfirm(true);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      setRefresh(!refreh);
    } else {
      setOpenLogin(true);
    }
  };


  return (
    <>
      {event ? (
        <>
          <Container sx={{ paddingTop: { xs: 6, sm: 10 }, paddingBottom: 20 }}>
            <AffTracker />
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                left: 0,
                zIndex: -1,
                filter: " blur(5rem)",
              }}
            >
              {event && (
                <CardMedia
                  component="img"
                  sx={{ width: "100%", objectFit: "cover" }}
                  height="300"
                  image={event.idVisuel}
                  title="green iguana"
                />
              )}
            </Box>
            <>
              <Grid
                marginY={1}
                direction={{ xs: "column-reverse", sm: "row" }}
                container
                spacing={5}
                display="flex"
                alignItems="start"
              >
                <Grid spacing={1} item xs={12} sm={5} sx={{ width: "100%" }}>

                  <Box
                    sx={{
                      padding: 2,
                      borderRadius: "7px",
                      width: "100%",
                      bgcolor: "rgba(245, 245, 245, 0.60)",
                      marginBottom: 2,
                    }}
                  >
                    <Box paddingBottom={2}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#141414",
                        }}
                      >
                        <strong>{event.name ? event.name : event.nomEvent}</strong>
                      </Typography>
                      <Stack direction={'row'} spacing={2}>
                        <Box
                          sx={{
                            border: " 1px solid #999999",
                            borderRadius: "20px",
                            bgcolor: "#1F1F1F",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Stack direction="row">
                            <Typography
                              variant="caption"
                              paddingX={2}
                              paddingY={0.5}
                              color="white"
                            >
                              {event.type}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                      {/* event.seanceActive === 1 && (
                    <Typography fontSize={20} color="#1F1F1F">
                      Multidate
                    </Typography>
                  ) */}
                    </Box>
                    <Grid paddingY={1} container>
                      <Grid item xs={1}>
                        <Iconify
                          inline={true}
                          icon="material-symbols:date-range-outline"
                          sx={{ width: 24, height: 24, color: "#1F1F1F" }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={8}>
                        {" "}
                        <Typography paddingBottom={1} fontSize={17}>
                          {event.dateDebut &&
                            format(
                              new Date(handleTimestamp(event.dateDebut)),
                              "EEE dd MMM yy ",
                              { locale: fr }
                            )}{" "}
                          {event.dateDebut &&
                            format(
                              new Date(handleTimestamp(event.dateDebut)),
                              "hh",
                              { locale: fr }
                            )}
                          h
                          {event.dateDebut &&
                            format(
                              new Date(handleTimestamp(event.dateDebut)),
                              "mm",
                              { locale: fr }
                            )}
                        </Typography>
                        <Divider />
                      </Grid>
                    </Grid>

                    <Grid paddingY={1} container>
                      <Grid item xs={1}>
                        <Iconify
                          inline={true}
                          icon="material-symbols:home-outline"
                          sx={{ width: 24, height: 24, color: "#1F1F1F" }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={8}>
                        {" "}
                        <Typography paddingBottom={1} fontSize={17}>
                          {event.lieu_event}
                        </Typography>
                        <Divider />
                      </Grid>
                    </Grid>

                    <Grid
                      paddingTop={1}
                      paddingBottom={2}
                      container
                      spacing={3}
                    >
                      <Grid item xs={1}>
                        <Iconify
                          inline={true}
                          icon="material-symbols:location-on-outline"
                          sx={{ width: 24, height: 24, color: "#1F1F1F" }}
                        />
                      </Grid>
                      <Grid item xs={10} sm={8}>
                        <Typography fontSize={17}>
                          {event.localisationDes ? event.localisationDes : `${event.lieu_event}, ${event.city}, ${event.country}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor="#F5F5F5"
                    padding={2}
                    sx={{
                      borderRadius: "7px",
                      width: "100%",
                      bgcolor: "rgba(245, 245, 245, 0.5)",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      display="flex"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <Typography paddingY={2} fontSize={17} color="#999999">
                        Organisé par{" "}
                      </Typography>
                      <Typography fontSize={17}>
                        <strong>{org.nomOrg}</strong>
                      </Typography>
                    </Stack>
                    {isFollow ? (
                      <Button
                        size="medium"
                        sx={{
                          textTransform: "initial",
                          border: "2px solid #5E17EB",
                          backgroundColor: "white",
                          color: "#5E17EB",
                          height: 40,
                          borderRadius: "7px",
                          "&:hover": {
                            backgroundColor: "white",
                            color: "#5E17EB",
                          },
                        }}
                        variant="contained"
                        onClick={() => {
                          follow();
                        }}
                      >
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <Stack
                            direction="row"
                            display="flex"
                            alignItems="center"
                          >
                            <Typography fontSize={14}>Abonner</Typography>{" "}
                            <Iconify
                              icon="flat-color-icons:ok"
                              sx={{ height: 20, width: 20 }}
                            />
                          </Stack>
                        )}
                      </Button>
                    ) : (
                      <Button
                        size="medium"
                        sx={{
                          textTransform: "initial",
                          border: "2px solid #5E17EB",
                          backgroundColor: "#5E17EB",
                          color: "white",
                          height: 40,
                          borderRadius: "7px",
                          "&:hover": {
                            backgroundColor: "#5E17EB",
                            color: "white",
                          },
                        }}
                        variant="contained"
                        onClick={() => {
                          follow();
                        }}
                      >
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <Typography fontSize={14}>S'abonner</Typography>
                        )}
                      </Button>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={7} sx={{ width: "100%" }}>
                  <Card
                    elevation={!smUp ? 0 : 10}
                    sx={{
                      borderRadius: { xs: "7px", sm: "7px" },
                      bgcolr: "white",
                    }}
                  >
                    {event &&
                      (!smUp ? (
                        <CardMedia
                          component="img"
                          sx={{ width: "100%", objectFit: "cover" }}
                          height="200"
                          image={event.idVisuel}
                          title="green iguana"
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          sx={{ width: "100%", objectFit: "cover" }}
                          height="425"
                          image={event.idVisuel}
                          title="green iguana"
                        />
                      ))}
                  </Card>
                </Grid>
              </Grid>
              <Grid spacing={5} container paddingY={5}>
                {seances.length !== 0 && smUp && (
                  <Grid item xs={12}>
                    <Typography paddingBottom={2} variant="h5" fontWeight={600}>
                      Choisissez votre date { }
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      {seances.map((seance, index) =>
                        seanceActive === index ? (
                          <Box
                            padding={2}
                            sx={{
                              borderRadius: "7px",
                              border: "2px solid transparent",
                              cursor: "pointer",
                              bgcolor: "#F2ECFD",
                              borderColor: "#5E17EB",
                              maxWidth: 80,
                              "&:hover": {
                                borderColor: "#5E17EB",
                              },
                            }}
                            onClick={() => changeSeance(index)}
                          >
                            {console.log(seance.data())}
                            <Typography
                              textAlign="center"
                              sx={{ textTransform: "uppercase" }}
                            >
                              {format(
                                new Date(handleTimestamp(seance.data().date)),
                                "EEE dd MMM",
                                { locale: fr }
                              )}
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            padding={2}
                            sx={{
                              borderRadius: "7px",
                              border: "2px solid transparent",
                              cursor: "pointer",
                              bgcolor: "#F2ECFD",
                              maxWidth: 80,
                              "&:hover": {
                                borderColor: "#5E17EB",
                              },
                            }}
                            onClick={() => changeSeance(index)}
                          >
                            <Typography
                              textAlign="center"
                              sx={{ textTransform: "uppercase" }}
                            >
                              {format(
                                new Date(handleTimestamp(seance.data().date)),
                                "EEE dd MMM",
                                { locale: fr }
                              )}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Stack>
                  </Grid>
                )}

                <Grid item xs={12} md={7}>
                  {smUp && (
                    <>
                      <Typography
                        paddingBottom={2}
                        variant="h5"
                        fontWeight={600}
                      >
                        Choisissez vos billets { }
                      </Typography>
                      {
                        // event passé
                        new Date(handleTimestamp(event.dateFin)) < today1 ?
                          <Typography
                            variant="h7"
                            fontWeight={600}
                            color="#1F1F1F"
                          >
                            L'evenement <strong>{event.nomEvent}</strong> est terminé
                          </Typography>
                          : new Date(handleTimestamp(event.dateFin)) > today1 ?
                            <Grid container spacing={3}>
                              {tickets.length === 0 && loading ? (
                                <>
                                  <Grid item xs={12} md={12}>
                                    <Skeleton variant="rectangular" height={150} />
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <Skeleton variant="rectangular" height={150} />
                                  </Grid>
                                </>
                              ) : tickets.length === 0 && !loading ? (
                                <Grid xs={12} md={12}>
                                  <Stack
                                    paddingTop={4}
                                    paddingX={4}
                                    spacing={3}
                                    direction="row"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Typography
                                      variant="h7"
                                      fontWeight={600}
                                      color="#1F1F1F"
                                    >
                                      Aucun billet mise à disposition par {org.nomOrg}{" "}
                                      pour l'instant, mais vous pourriez être
                                      intéressé d'autres événements.
                                    </Typography>
                                  </Stack>
                                </Grid>
                              ) : (
                                tickets.map((ticket, index) => (
                                  <Grid item xs={12} md={12}>
                                    <Box
                                      sx={{
                                        padding: 4,
                                        bgcolor: "#F3EDFD",
                                        color: "#1F1F1F",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid>
                                          <Typography fontSize={17} fontWeight={600}>
                                            {ticket.data().ticket_name}
                                          </Typography>
                                          <Typography fontSize={17}>
                                            {ticket.data().price === 0
                                              ? "Gratuit"
                                              : ticket.data().price + " CFA"}
                                          </Typography>
                                        </Grid>
                                        {seances.length !== 0 ? (
                                          <Grid>
                                            {seances[seanceActive].data().billets[
                                              seances[seanceActive]
                                                .data()
                                                .billets.findIndex(
                                                  ({ id }) => id === ticket.id
                                                )
                                            ].quota ===
                                              seances[seanceActive].data().billets[
                                                seances[seanceActive]
                                                  .data()
                                                  .billets.findIndex(
                                                    ({ id }) => id === ticket.id
                                                  )
                                              ].sell &&
                                              seances[seanceActive].data().billets[
                                                seances[seanceActive]
                                                  .data()
                                                  .billets.findIndex(
                                                    ({ id }) => id === ticket.id
                                                  )
                                              ].sell !== 0 ? (
                                              <Box
                                                component="button"
                                                padding={0.5}
                                                sx={{
                                                  borderRadius: "5px",
                                                  fontSize: 11,
                                                  backgroundColor: "#FBE79D",
                                                  border: "1px dashed #FBE79D",
                                                }}
                                              >
                                                épuisé
                                              </Box>
                                            ) : (
                                              <Stack direction="row" spacing={1}>
                                                {quantite[index] === 0 ? (
                                                  <IconButton
                                                    size="small"
                                                    disabled
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton
                                                    onClick={() =>
                                                      removeTicket(index)
                                                    }
                                                    size="small"
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                )}
                                                <Typography fontSize={17}>
                                                  {quantite[index]}
                                                </Typography>
                                                <IconButton
                                                  onClick={() => addTicket(index)}
                                                  size="small"
                                                  aria-label="fingerprint"
                                                  sx={{
                                                    bgcolor: "#5E17EB",
                                                    color: "white",
                                                  }}
                                                >
                                                  <Iconify icon="material-symbols:add" />
                                                </IconButton>
                                              </Stack>
                                            )}
                                          </Grid>
                                        ) : (
                                          <Grid>
                                            {ticket.data().quota ===
                                              ticket.data().sell &&
                                              ticket.data().sell !== 0 ? (
                                              <Box
                                                component="button"
                                                padding={0.5}
                                                sx={{
                                                  borderRadius: "5px",
                                                  fontSize: 11,
                                                  backgroundColor: "#FBE79D",
                                                  border: "1px dashed #FBE79D",
                                                }}
                                              >
                                                épuisé
                                              </Box>
                                            ) : (
                                              <Stack direction="row" spacing={1}>
                                                {quantite[index] === 0 ? (
                                                  <IconButton
                                                    size="small"
                                                    disabled
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton
                                                    onClick={() =>
                                                      removeTicket(index)
                                                    }
                                                    size="small"
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                )}
                                                <Typography fontSize={17}>
                                                  {quantite[index]}
                                                </Typography>
                                                <IconButton
                                                  onClick={() => addTicket(index)}
                                                  size="small"
                                                  aria-label="fingerprint"
                                                  sx={{
                                                    bgcolor: "#5E17EB",
                                                    color: "white",
                                                  }}
                                                >
                                                  <Iconify icon="material-symbols:add" />
                                                </IconButton>
                                              </Stack>
                                            )}
                                          </Grid>
                                        )}
                                      </Grid>
                                      {ticket.data().description &&
                                        ticket.data().description !== "" && (
                                          <Grid>
                                            <Box>
                                              <Accordion
                                                elevation={0}
                                                sx={{ backgroundColor: "#F3EDFD" }}
                                              >
                                                <AccordionSummary
                                                  expandIcon={
                                                    <Iconify icon="ep:arrow-down-bold" />
                                                  }
                                                  aria-controls="panel1a-content"
                                                  id="panel1a-header"
                                                >
                                                  <Typography
                                                    fontSize={17}
                                                    sx={{ color: "#1F1F1F" }}
                                                  >
                                                    Détail sur le billet
                                                  </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                  sx={{
                                                    backgroundColor: "white",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  <Typography variant="body2">
                                                    {ticket.data().description}
                                                  </Typography>
                                                </AccordionDetails>
                                              </Accordion>
                                            </Box>
                                          </Grid>
                                        )}
                                    </Box>
                                  </Grid>
                                ))
                              )}
                            </Grid>
                            : null
                      }

                    </>
                  )}
                  <Stack paddingTop={smUp ? 10 : 2}
                    paddingBottom={2} direction={"row"} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography

                      variant="h5"
                      fontWeight={600}
                    >
                      Description
                    </Typography>
                    <Typography
                      variant="caption"
                    >
                      afficher
                    </Typography>
                  </Stack>

                  <Box sx={{ bgcolor: '#f2f2f2', borderRadius: '10px' }}>
                    {desc !== "" ? (
                      <Typography fontSize={17} color="#1F1F1F">
                        <ReactQuill
                          theme="bubble"
                          readOnly={true}
                          //modules={modules}
                          formats={formats}
                          value={desc}
                        //onChange={handleChangeQuill}
                        />
                      </Typography>
                    ) : (
                      <Typography fontSize={17} color="#1F1F1F">
                        Aucune description renseignée par l'organisateur !
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Typography
                      paddingTop={smUp ? 10 : 5}
                      paddingBottom={2}
                      variant="h5"
                      fontWeight={600}
                    >
                      Location
                    </Typography>
                    <Typography fontSize={17} color="#1F1F1F">
                      {event.lieu_event}, {event.city}, {event.country}
                    </Typography>
                    <Typography fontSize={17} color="#1F1F1F">
                      {event.infoLieu}
                    </Typography>
                    <Box sx={{ width: "100%" }}>
                      <Maps centerC={event.localisation} />
                    </Box>
                  </Box>
                  <Typography
                    paddingTop={smUp ? 10 : 5}
                    paddingBottom={2}
                    variant="h5"
                    fontWeight={600}
                  >
                    Organisateur
                  </Typography>
                  <Box
                    elevation={3}
                    sx={{
                      padding: 5,
                      borderRadius: '10px',
                      bgcolor: '#f2f2f2'
                    }}
                  >
                    <Stack spacing={2}>
                      <Typography fontSize={17} align="center">Organisé par</Typography>
                      <Box display="flex" justifyContent="center">
                        <Avatar src={org.idVisuel} sx={{ width: 125, height: 125 }}></Avatar>
                      </Box>
                      <Typography align="center">{org.nomOrg}</Typography>
                      <Grid container spacing={2} >
                        <Grid item xs={12} sm={6}>
                          <Button
                            size="large"
                            fullWidth
                            sx={{
                              textTransform: "initial",
                              border: "2px solid transparent",
                              color: "white",
                              backgroundColor: "black",
                              borderRadius: "7px",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "black",
                                borderColor: "black",
                              },
                            }}
                            variant="contained"
                            onClick={handleOpenContact}
                          >
                            Contactez
                          </Button>
                        </Grid>
                        {/** contact modal */}
                        <Modal
                          open={openContact}
                          onClose={handleCloseContact}
                          aria-labelledby="parent-modal-title"
                          aria-describedby="parent-modal-description"
                        >
                          <Contact />
                        </Modal>
                        <Grid item xs={12} sm={6}>
                          <Button
                            size="large"
                            fullWidth
                            sx={{
                              textTransform: "initial",
                              border: "2px solid transparent",
                              color: "white",
                              backgroundColor: "#5E17EB",
                              borderRadius: "7px",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#5E17EB",
                                borderColor: "#5E17EB",
                              },
                            }}
                            variant="contained"
                            onClick={() => {
                              window.open(`/fr/organisateur/${event.orgId}`);
                            }}
                          >
                            Profil organisateur
                          </Button>
                        </Grid>
                      </Grid>
                    </Stack>

                  </Box>

                </Grid>

                {/** bouton utilisé sur mobile */}
                {!smUp && (
                  <Card
                    elevation={5}
                    sx={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      paddingX: 5,
                      paddingY: 2,
                    }}
                  >
                    <Typography fontSize={17} align="center" paddingBottom={2}>
                      {event && event.minPrice !== 0 ? `à partir de ${event.minPrice} CFA` : 'Cet event propose des tickets gratuits'}
                    </Typography>
                    <CustomButton fullWidth type='primaire' buttonText={'Reservé maintenant'} onClick={handleOpen} />
                  </Card>
                )}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Stack padding={1} direction="row">
                      <IconButton onClick={handleClose}>
                        <Iconify
                          icon="solar:close-circle-linear"
                          sx={{ width: 24, height: 24 }}
                        />
                      </IconButton>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          textAlign="center"
                          color="#999999"
                        >
                          {event.nomEvent}
                        </Typography>
                      </Box>
                    </Stack>
                    <Divider />
                    <ReserMobile eventID={eventID} />
                  </Box>
                </Modal>

                {ticketsList.length !== 0 && smUp ? (
                  <Grid
                    sx={{ position: "", top: "8%", left: "60%" }}
                    item
                    xs={12}
                    md={5}
                  >
                    <Card
                      sx={{
                        padding: 3,
                        border: "2px solid #F3EDFD ",
                        borderRadius: "7px",
                      }}
                    >
                      <Typography variant="h5" fontWeight={600}>
                        x{ticketTotals}
                      </Typography>
                      <Typography fontSize={17}>
                        Billets selectionné(s)
                      </Typography>
                      <Stack marginY={2} spacing={1}>
                        {ticketsList.map((billet, index) => (
                          <Box bgcolor="#f2f2f2" borderRadius={'10px'} padding={2}>
                            <Typography>{billet.nom}</Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize={17}>
                                {billet.quantite}
                              </Typography>
                              <Typography fontSize={17}>
                                {billet.prix === 0
                                  ? "Gratuit"
                                  : billet.prix + " CFA"}
                              </Typography>
                            </Stack>
                          </Box>
                        ))}
                      </Stack>

                      <Divider />
                      <Stack
                        paddingY={2}
                        spacing={1}
                        direction="column"
                        justifyContent="space-between"
                      >
                        {addDiscount ? (
                          <>
                            {discountMsg !== "" &&
                              discountMsg.includes("Code validé") && (
                                <Typography
                                  color="green"
                                  fontSize={14}
                                  textAlign="center"
                                >
                                  {discountMsg}
                                </Typography>
                              )}
                            {discountMsg !== "" &&
                              discountMsg.includes("Code non valable") && (
                                <Typography
                                  color="red"
                                  fontSize={14}
                                  textAlign="center"
                                >
                                  {discountMsg}
                                </Typography>
                              )}
                            <TextField
                              size="small"
                              value={discountField}
                              onChange={(e) => {
                                setDiscountField(e.target.value);
                              }}
                            />
                            <Button
                              size="large"
                              fullWidth
                              sx={{
                                textTransform: "initial",
                                border: "2px solid transparent",
                                color: "white",
                                backgroundColor: "black",
                                borderRadius: "7px",
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "black",
                                  borderColor: "black",
                                },
                              }}
                              disabled={discountField === "" ? true : false}
                              variant="contained"
                              onClick={() => {
                                setStartVerifyDiscount(!startVerifyDiscount);
                              }}
                            >
                              Valider
                            </Button>
                          </>
                        ) : (
                          <Button
                            size="large"
                            fullWidth
                            sx={{
                              textTransform: "initial",
                              border: "2px solid transparent",
                              color: "white",
                              backgroundColor: "black",
                              borderRadius: "7px",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "black",
                                borderColor: "black",
                              },
                            }}
                            variant="contained"
                            onClick={() => {
                              setAddDiscount(true);
                            }}
                          >
                            Ajouter un code promo
                          </Button>
                        )}
                      </Stack>
                      <Divider />
                      <Stack
                        paddingY={2}
                        direction="column"
                        justifyContent="center"
                      >
                        {discount && (
                          <>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize={16} fontWeight={500}>
                                Total hors réduction :
                              </Typography>
                              <Typography fontSize={16} fontWeight={500}>
                                {montant} CFA{" "}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize={14} fontWeight={500}>
                                Réduction :
                              </Typography>
                              <Typography fontSize={14} fontWeight={500}>
                                {discount.value} CFA{" "}
                              </Typography>
                            </Stack>
                          </>
                        )}
                        <Stack direction="row" justifyContent="space-between">
                          <Typography fontSize={17} fontWeight={600}>
                            Total :
                          </Typography>
                          <Typography fontSize={17} fontWeight={600}>
                            {discount ? montant - discount.value : montant} CFA{" "}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Button
                        onClick={() => {
                          setOpenReservation(true);
                        }}
                        size="large"
                        fullWidth
                        sx={{
                          textTransform: "initial",
                          border: "2px solid transparent",
                          color: "white",
                          backgroundColor: "#5E17EB",
                          borderRadius: "7px",
                          "&:hover": {
                            backgroundColor: "white",
                            color: "#5E17EB",
                            borderColor: "#5E17EB",
                          },
                        }}
                        variant="contained"
                      >
                        Reserver
                      </Button>
                      <Modal
                        open={openReservation}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <ReserDesktop
                          event={event}
                          ticketsList={ticketsList}
                          montant={montant}
                          reduction={discount}
                          seance={
                            seances.length !== 0
                              ? seances[seanceActive].id
                              : null
                          }
                        />
                      </Modal>
                    </Card>
                  </Grid>
                ) : null}
                {/* ticketsList.length !== 0 && smUp ? (
                  <div ref={ref}>
                  <Grid
                    sx={{
                      position:  {isSticky === 0 ? 'fixed': 'relative' },
                      top: "7%",
                      left: { md: "70%", lg: "56.5%", xl: "54.2%" },
                      width: { md: "41.2%", xl: "26.1%" },
                    }}
                    item
                    xs={12}
                    md={5}
                  >
                    <Card
                      sx={{
                        padding: 3,
                        border: "2px solid #5E17EB ",
                        borderRadius: "7px",
                      }}
                    >
                      <Typography variant="h5" fontWeight={600}>
                        x{ticketTotals} sticky: {isSticky}
                      </Typography>
                      <Typography fontSize={18}>
                        Billets selectionné(s)
                      </Typography>
                      <Stack marginY={2} spacing={1}>
                        {ticketsList.map((billet, index) => (
                          <Box bgcolor="gainsboro" padding={2}>
                            <Typography>{billet.nom}</Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize={18}>
                                {billet.quantite}
                              </Typography>
                              <Typography fontSize={18}>
                                {billet.prix} CFA
                              </Typography>
                            </Stack>
                          </Box>
                        ))}
                      </Stack>

                      <Divider />
                      <Stack
                        paddingY={2}
                        spacing={1}
                        direction="column"
                        justifyContent="space-between"
                      >
                        {addDiscount ? (
                          <>
                            {discountMsg !== "" &&
                              discountMsg.includes("Code validé") && (
                                <Typography
                                  color="green"
                                  fontSize={14}
                                  textAlign="center"
                                >
                                  {discountMsg}
                                </Typography>
                              )}
                            {discountMsg !== "" &&
                              discountMsg.includes("Code non valable") && (
                                <Typography
                                  color="red"
                                  fontSize={14}
                                  textAlign="center"
                                >
                                  {discountMsg}
                                </Typography>
                              )}
                            <TextField
                              size="small"
                              value={discountField}
                              onChange={(e) => {
                                setDiscountField(e.target.value);
                              }}
                            />
                            <Button
                              size="large"
                              fullWidth
                              sx={{
                                textTransform: "initial",
                                border: "2px solid transparent",
                                color: "white",
                                backgroundColor: "black",
                                borderRadius: "7px",
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "black",
                                  borderColor: "black",
                                },
                              }}
                              disabled={discountField === "" ? true : false}
                              variant="contained"
                              onClick={() => {
                                setStartVerifyDiscount(!startVerifyDiscount);
                              }}
                            >
                              Valider
                            </Button>
                          </>
                        ) : (
                          <Button
                            size="large"
                            fullWidth
                            sx={{
                              textTransform: "initial",
                              border: "2px solid transparent",
                              color: "white",
                              backgroundColor: "black",
                              borderRadius: "7px",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "black",
                                borderColor: "black",
                              },
                            }}
                            variant="contained"
                            onClick={() => {
                              setAddDiscount(true);
                            }}
                          >
                            Ajouter un code promo
                          </Button>
                        )}
                      </Stack>
                      <Divider />
                      <Stack
                        paddingY={2}
                        direction="column"
                        justifyContent="center"
                      >
                        {discount && (
                          <>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize={16} fontWeight={500}>
                                Total hors réduction :
                              </Typography>
                              <Typography fontSize={16} fontWeight={500}>
                                {montant} CFA{" "}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize={14} fontWeight={500}>
                                Réduction :
                              </Typography>
                              <Typography fontSize={14} fontWeight={500}>
                                {discount.value} CFA{" "}
                              </Typography>
                            </Stack>
                          </>
                        )}
                        <Stack direction="row" justifyContent="space-between">
                          <Typography fontSize={18} fontWeight={600}>
                            Total :
                          </Typography>
                          <Typography fontSize={18} fontWeight={600}>
                            {discount ? montant - discount.value : montant} CFA{" "}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Button
                        onClick={() => {
                          setOpenReservation(true);
                        }}
                        size="large"
                        fullWidth
                        sx={{
                          textTransform: "initial",
                          border: "2px solid transparent",
                          color: "white",
                          backgroundColor: "#5E17EB",
                          borderRadius: "7px",
                          "&:hover": {
                            backgroundColor: "white",
                            color: "#5E17EB",
                            borderColor: "#5E17EB",
                          },
                        }}
                        variant="contained"
                      >
                        Reserver
                      </Button>
                      <Modal
                        open={openReservation}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <ReserDesktop
                          event={event}
                          ticketsList={ticketsList}
                          montant={montant}
                          reduction={discount}
                        />
                      </Modal>
                    </Card>
                  </Grid></div>
                ) : null */}
              </Grid>
              <Stack display="row" alignContent="center" alignItems="center">
                <Stack>
                  <Iconify />
                </Stack>
              </Stack>
            </>
          </Container>

          <Footer />
        </>
      ) : (
        <CircularProgress sx={{ color: "#5E17EB" }} />
      )}
    </>
  );
}
